import SSPContentExt from 'sspcontentext';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Context from './context';
import {
    INITIAL_STATE,
    reducer,
    SET_CONTENT_ACTION,
    SET_SSP_ACTION,
} from './store';
import { getSetup } from '../Functions/getSetup';
import { checkForUpdates } from './checkForUpdates';
import { customFetch } from '../Functions/customFetch';
import ErrorPage from '../Components/ErrorPage';
import { addMexData } from '../Functions/addMexData';

let initial = true;
let progress: HTMLDivElement | null = null;

const SspContextProvider = (props: {
    children: React.ReactNode;
    url: string;
    vin: string | null;
    setupLink: string;
    prepareOfflineUse: boolean;
    showLabel?: boolean;
}): JSX.Element => {
    const setup = getSetup();
    let url = props.url;
    const vin = props.vin;
    let fetchOptions: RequestInit | undefined = {
        method: 'GET',
        headers: {},
    };
    const params = new URLSearchParams(window.location.search);
    let device = params.get('device') as string;
    const devMode = params.get('dev') === 'true';
    let auth = '';

    if (
        setup &&
        (setup.url || (setup.market?.name && setup.brand && setup.kvps)) &&
        setup.password
    ) {
        url = setup.url
            ? setup.url
            : `${setup.market.name.toLowerCase()}${setup.kvps}.${
                  setup.brand
              }-de.did.b12-ssp.de`;
        auth = window.btoa(`ssp:${setup.password}`);
        fetchOptions = {
            method: 'GET',
            headers: {
                Authorization: 'Basic ' + auth,
            },
        };
        device = setup.device;
    } else if (setup) {
        return <ErrorPage msg={'Incorrect Setup'} link={props.setupLink} />;
    } else {
        return <ErrorPage msg={'No Setup Found'} link={props.setupLink} />;
    }

    if (!progress && props.prepareOfflineUse) {
        progress = document.createElement('div');
        progress.setAttribute('class', 'caching-progress');
        document.getElementsByTagName('body')[0].append(progress);
    }

    /**
     * state for global ssp context
     * provider
     */
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [error, setError] = useState<{
        msg: string;
        status?: number | null;
    } | null>(null);

    async function cache(fetchURL: string, type?: string): Promise<string> {
        let cacheName = 'did-web-assets-a-' + setup.brand;
        if ('01234'.includes(fetchURL.slice(-1))) {
            cacheName = 'did-web-assets-b-' + setup.brand;
        }
        if ('56789'.includes(fetchURL.slice(-1))) {
            cacheName = 'did-web-assets-c-' + setup.brand;
        }

        const check = await caches.open(cacheName).then(cache => {
            return cache.match(fetchURL).then(
                res => res !== undefined,
                () => false
            );
        });

        if (devMode || check) {
            return new Promise<string>(async (resolve, reject) =>
                resolve(fetchURL)
            );
        }

        return new Promise<any>(async (resolve, reject) => {
            customFetch(fetchURL, auth, type)
                .then(async fetchResponse => {
                    if (
                        !(
                            fetchResponse.status !== 204 &&
                            fetchResponse.status !== 206
                        )
                    ) {
                        console.warn(
                            `failed to cache ${fetchURL} with status ${fetchResponse.status}`
                        );
                    } else {
                        caches
                            .open(cacheName)
                            .then(cache => cache.put(fetchURL, fetchResponse))
                            .then(() => resolve(fetchURL));
                    }
                })
                .catch(error => {
                    console.log(
                        `SSPContextProvider - failed to fetch ${fetchURL}`,
                        error
                    );
                });
        });
    }

    function initWithJsonURL(
        ext: SSPContentExt,
        jsonURL: string
    ): Promise<any> {
        return customFetch(jsonURL, auth)
            .then(response => {
                if (response.status === 200) {
                    return caches
                        .open('did-web-precache-' + setup.brand)
                        .then(cache => cache.put(jsonURL, response.clone()))
                        .then(() => {
                            return response.json().then(
                                json => json,
                                reason =>
                                    setError({ msg: `${reason} ${jsonURL}` })
                            );
                        });
                } else {
                    setError({ msg: jsonURL, status: response.status });
                }
            })
            .then(async (json: Array<object> | undefined) => {
                if (json) {
                    ext.initWithModels(json);
                    const p = document.createElement('p');
                    p.innerText = `VIN: ${ext.projectModel.asModel
                        .ref('root_content')
                        .asModel.str('vin')}`;
                    p.className = 'vin';
                    document.getElementById('#loading-page')?.appendChild(p);

                    let cached = 0;
                    if (props.prepareOfflineUse)
                        await Promise.all(
                            json.map(async (asset: any) => {
                                if (
                                    (asset.ContentType === 'assetImage' ||
                                        asset.ContentType === 'assetDocument' ||
                                        asset.ContentType === 'assetUnknown') &&
                                    asset.AssetDataSet !== null
                                ) {
                                    const fetchURL = ext.getAssetUrl(
                                        asset.AssetDataSet.AssetFileProperties
                                            .FileHash
                                    );
                                    await cache(
                                        fetchURL,
                                        asset.AssetDataSet.AssetFileProperties
                                            .FileType === 'svg'
                                            ? 'image/svg+xml'
                                            : undefined
                                    );
                                }
                                if (asset.ContentType === 'assetVideo') {
                                    // if video caches additional thumbnail image
                                    const fetchURL = ext.getAssetUrl(
                                        asset.AssetDataSet.PosterFileProperties
                                            .FileHash
                                    );
                                    await cache(fetchURL);
                                    if (
                                        asset.AssetDataSet.AssetFileProperties
                                            .Size <= 300000000
                                    ) {
                                        const videoFetchURL = ext.getAssetUrl(
                                            asset.AssetDataSet
                                                .AssetFileProperties.FileHash
                                        );
                                        await cache(videoFetchURL);
                                    }
                                }

                                cached += 1;
                                if (progress) {
                                    progress.textContent =
                                        cached === json.length
                                            ? ''
                                            : `${cached}/${json.length}`;
                                }
                            })
                        );
                }
            })
            .catch(err => {
                Promise.reject(err);
            });
    }

    async function tryInit(ext: SSPContentExt, url: string) {
        if ('serviceWorker' in navigator) {
            const hasSW =
                (await navigator.serviceWorker.getRegistrations()).length > 0;
            if (hasSW) {
                await initWithJsonURL(ext, url);
            } else {
                setTimeout(tryInit, 500);
            }
        } else {
            await initWithJsonURL(ext, url);
        }
    }

    /**
     * inits ssp to load data
     * from the server
     *
     */
    const initSSP = async () => {
        try {
            /**
             * create class that handles the SSPContentExt
             */
            const ext = SSPContentExt.instance;
            if (device && !devMode) {
                checkForUpdates(
                    url,
                    device,
                    fetchOptions,
                    setup.brand,
                    props.showLabel
                );
            }

            const sspWeb = {
                ext,
                init: async () => {
                    try {
                        ext.configure(url, true);
                        await tryInit(
                            ext,
                            device
                                ? `${url}/module/content/bpca/depot/terminals/${device}/allcontents.json`
                                : `${url}/module/didKansas/bpca/pricesheetByVin/${vin}/allcontents.json`
                        );
                    } catch (e) {
                        console.error(
                            `Couldn't initialize SSP. Error: ${error}`
                        );
                    }
                },
            };

            await sspWeb.init();

            return sspWeb;
        } catch (error) {
            throw new Error(`${error}`);
        }
    };

    /**
     * retrieves the initial content from ssp
     */
    const handleInitialPageLoad = useCallback(async () => {
        try {
            /**
             * set the language for ssp provider by given
             * url param
             */

            let sspExt = await initSSP();

            /**
             * load data from ssp and pass it to
             * the ssp provider store
             */
            if (sspExt) {
                if (
                    sspExt.ext.projectModel &&
                    sspExt.ext.projectModel.asModel.contentTreeChildren.length >
                        0
                ) {
                    const priceSheet =
                        sspExt.ext.projectModel.asModel.contentTreeChildren[0];
                    const pricesheetTitle =
                        priceSheet.asModel.str('title') +
                        ' ' +
                        priceSheet.asModel.str('subtitle');
                    const carline = priceSheet.asModel.str('carline');
                    let carlineName = priceSheet.asModel.str('title');
                    if (carlineName.includes('Audi')) {
                        carlineName = carlineName.replace('Audi', '');
                    } else if (carlineName.includes('VW')) {
                        carlineName = carlineName.replace('VW', '');
                    }
                    const salesgroup = priceSheet.asModel.str('salesgroup');
                    const salesgroupName =
                        priceSheet.asModel.reflist('salesgroupref')[0] &&
                        priceSheet.asModel
                            .reflist('salesgroupref')[0]
                            .asModel.str('name');
                    const modelKey = priceSheet.asModel
                        .ref('engine')
                        .asModel.str('motorkey');
                    const engineName = priceSheet.asModel
                        .ref('engine')
                        .asModel.str('name');
                    const modelYear = priceSheet.asModel.str('modelyear');
                    const vin = priceSheet.asModel.str('vin');
                    const commissionNumber =
                        priceSheet.asModel.str('commissionnr');
                    const car = priceSheet.contentType;
                    const pricesheetUID = priceSheet.uid;
                    const menuUID = priceSheet.asModel.str('salesgroupref');
                    const suite = sspExt.ext.projectModel.name;
                    sessionStorage.setItem('pricesheetTitle', pricesheetTitle);
                    sessionStorage.setItem('carline', carline);
                    sessionStorage.setItem('carlineName', carlineName);
                    sessionStorage.setItem('salesgroup', salesgroup);
                    sessionStorage.setItem('salesgroupName', salesgroupName);
                    sessionStorage.setItem('modelKey', modelKey);
                    sessionStorage.setItem('engineName', engineName);
                    sessionStorage.setItem('modelYear', modelYear);
                    sessionStorage.setItem('vin', vin);
                    sessionStorage.setItem(
                        'commissionNumber',
                        commissionNumber
                    );
                    sessionStorage.setItem('car', car);
                    sessionStorage.setItem('pricesheetUID', pricesheetUID);
                    sessionStorage.setItem('menuUID', menuUID);
                    sessionStorage.setItem('suite', suite);
                    if (setup.market.name === 'MEX') {
                        await addMexData(priceSheet);
                    }
                    dispatch({ type: SET_CONTENT_ACTION, value: priceSheet });
                    dispatch({ type: SET_SSP_ACTION, value: sspExt.ext });
                }
            } else {
                const retrySSPConnectionInterval = setInterval(async () => {
                    sspExt = await initSSP();

                    if (!sspExt) {
                        clearInterval(retrySSPConnectionInterval);
                    } else {
                        dispatch({ type: SET_SSP_ACTION, value: sspExt.ext });
                    }
                }, 1000);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        if (initial) {
            initial = false;
            handleInitialPageLoad();
        }
    }, [handleInitialPageLoad]);

    const getContextValue = useCallback(
        () => ({
            state,
            dispatch,
        }),
        [state, dispatch]
    );

    if (error) {
        return (
            <ErrorPage
                msg={error.msg}
                status={error.status || undefined}
                setup={setup}
                link={props.setupLink}
            />
        );
    }

    return (
        <Context.Provider value={getContextValue()}>
            {props?.children}
        </Context.Provider>
    );
};

export default SspContextProvider;
