import React, { useContext } from 'react';
import SspProviderContext from '../../did-utility/src/SspProvider/context';
import Overlay from '../Overlay';
import { OverlayEnum } from '../../did-utility';

export default function OverlaysMap(): JSX.Element {
	const store = useContext(SspProviderContext);

	return (
		<>
			{store.state.overlays
				.filter(overlay => overlay !== OverlayEnum.Menu)
				.map((overlay, index) => (
					<React.Fragment key={`overlay-${index}`}>
						<Overlay overlay={overlay} />
					</React.Fragment>
				))}
			{store.state.overlays.length === 0 && store.state.qr !== null && (
				<Overlay overlay={null} />
			)}
		</>
	);
}
