export const isoToEng: { [key: string]: string } = {
    ar: 'arabic',
    as: 'assamese',
    be: 'belarusian',
    bn: 'bengali',
    my: 'burmese',
    zh: 'chinese',
    cs: 'czech',
    en: 'english',
    fr: 'french',
    fa: 'farsi',
    ka: 'georgian',
    de: 'german',
    el: 'greek',
    he: 'hebrew',
    hi: 'hindi',
    hu: 'hungarian',
    it: 'italian',
    ja: 'japanese',
    kn: 'kannada',
    ko: 'korean',
    ml: 'malayalam',
    nb: 'norwegian',
    nn: 'norwegian',
    no: 'norwegian',
    pl: 'polish',
    ru: 'russian',
    sd: 'sindhi',
    es: 'spanish',
    sv: 'swedish',
    th: 'thai',
    tr: 'turkish',
    ug: 'uyghur',
    uk: 'ukrainian',
    ur: 'urdu',
};
