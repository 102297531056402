import React, { useEffect, useState } from 'react';

export default function BuildInfo(props: { local: string | undefined }) {
    const [info, setInfo] = useState<string | null>(
        new URLSearchParams(window.location.search).get('build-info')
            ? null
            : ''
    );

    const formatDate = (timestamp: string) =>
        props.local
            ? new Date(timestamp).toLocaleString(props.local, {})
            : new Date(timestamp).toLocaleString();

    useEffect(() => {
        if (info === null) {
            fetch('./build-info.json')
                .then(r => {
                    return r.headers.get('Content-Type') === 'application/json'
                        ? r.json()
                        : null;
                })
                .then(infoJson => {
                    if (infoJson)
                        setInfo(` (Debug: ${formatDate(infoJson.timestamp)})`);
                });
        }
    }, [props.local]);

    return <>{info ? info : ''}</>;
}
