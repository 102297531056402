import React from 'react';
import VideoPlayer from '../../did-utility/src/Components/VideoPlayer';
import { VideoPlayerBrandProps } from '../../did-utility/src/Components/VideoPlayer/interfaces';
import './styles.scss';

export default function VideoPlayerVW(props: VideoPlayerBrandProps) {
	return (
		<VideoPlayer
			{...props}
			play={
				<svg>
					<use xlinkHref='#play-circle' />
				</svg>
			}
			pause={
				<svg>
					<use xlinkHref='#pause-icon' />
				</svg>
			}
			volumeOn={
				<svg>
					<use xlinkHref='#volume-medium' />
				</svg>
			}
			volumeOff={
				<svg>
					<use xlinkHref='#volume-mute' />
				</svg>
			}
		/>
	);
}
