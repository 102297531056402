import React, { useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { KeyboardWrapperProps } from './interfaces';
import './styles.scss';
import SimpleKeyboardLayouts from 'simple-keyboard-layouts';
import { useTranslation } from 'react-i18next';
import { isoToEng } from './langCodeToEnglish';

export default function KeyboardWrapper(
    props: KeyboardWrapperProps
): JSX.Element {
    const ref = props.keyboardRef;
    const [layoutName, setLayoutName] = useState('default');
    const layouts = new SimpleKeyboardLayouts();
    const { i18n } = useTranslation();
    const langCode = props.langCode || i18n.language.split('-')[0];
    const layoutCandidates =
        (layouts.get(isoToEng[langCode]) as any).layoutCandidates || undefined;

    const onKeyPress = (button: string) => {
        if (button === '{shift}' || button === '{lock}') {
            setLayoutName(layoutName === 'default' ? 'shift' : 'default');
        }
        if (button === '{enter}') {
            props.onEnter();
        }
    };

    return (
        <Keyboard
            keyboardRef={ref ? r => (ref.current = r) : undefined}
            layoutName={layoutName}
            onChange={props.onChange}
            onKeyPress={onKeyPress}
            layout={layouts.layouts[isoToEng[langCode] || 'english'].layout}
            display={{
                '{bksp}': '\u21E6',
                '{enter}': props.enterString || '\u21B2',
                '{shift}': '\u21E7',
                '{space}': ' ',
            }}
            enableLayoutCandidates={layoutCandidates !== undefined}
            layoutCandidates={layoutCandidates}
        />
    );
}
