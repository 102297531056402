import React from 'react';
import { getNumberString } from '../../did-utility';
import SSPContentFieldAccessor from 'sspcontentext/src/model/accessor/SSPContentFieldAccessor';
import i18next from 'i18next';

export function UpperTableElement(props: {
	headline: string;
	value?: string | number;
	valuep2?: string;
	valuep3?: string;
	valuep4?: string;
	unit?: string;
	unitp2?: string;
	unitp3?: string;
	unitp4?: string;
	add?: any;
	disclaimer?: number | undefined;
	efficiency?: boolean | undefined;
	svg?: string | number;
}) {
	return (
		<div className='upper-table-element'>
			<p>{i18next.t(props.headline)}</p>
			{props.value && (
				<>
					<h2 className='light'>
						<span className='h2 bold'>{props.value}</span>{' '}
						{props.unit}
						{props.disclaimer && props.disclaimer !== -1 && (
							<sup>{props.disclaimer}</sup>
						)}
					</h2>
					{props.add && props.add}
				</>
			)}
			{props.valuep2 && (
				<h2 className='light'>
					{' / '}
					<span className='h2 bold'>{props.valuep2}</span>{' '}
					{props.unitp2}
				</h2>
			)}
			{props.svg && (
				<svg className='critair'>
					{props.svg === 1 ? (
						<use xlinkHref='#critAir1' />
					) : props.svg === 2 ? (
						<use xlinkHref='#critAir2' />
					) : props.svg === 'bev' ? (
						<use xlinkHref='#critAirBEV' />
					) : (
						<use />
					)}
				</svg>
			)}
		</div>
	);
}

export function UpperTableElementNumber(props: {
	headline: string;
	value: number;
	unit: string;
	add?: any;
	disclaimer?: number | undefined;
}) {
	return (
		<UpperTableElement
			{...props}
			value={getNumberString(props.value, i18next.language, undefined, 1)}
			unit={i18next.t(props.unit)}
		/>
	);
}

export function UpperTableElementConsumption(props: {
	headline: string;
	value: string;
	unit: string;
	model: SSPContentFieldAccessor;
	engine?: SSPContentFieldAccessor;
	add?: any;
	disclaimer?: number | undefined;
}) {
	const keyNotElectric = props.value.replace('electric_', '');
	const unitNotElectric = props.unit.replace('Electric', 'Fuel');

	const getValue = () => {
		if (props.engine?.str('engine_type') === 'Hybrid') {
			const fuel = getNumberString(
				props.model.float(keyNotElectric, NaN),
				i18next.language
			);

			const electric = getNumberString(
				props.model.float(props.value, NaN),
				i18next.language,
				undefined,
				1
			);
			return `${fuel} ${props.engine?.str('fuel') || ''}\n${electric} ${
				props.engine?.str('fuel2') || ''
			}`;
		}

		if (props.engine?.str('engine_type') === 'Electric') {
			return getNumberString(
				props.model.float(props.value, NaN),
				i18next.language,
				undefined,
				1
			);
		}

		return getNumberString(
			props.model.float(keyNotElectric, NaN),
			i18next.language,
			undefined,
			1
		);
	};

	return (
		<UpperTableElement
			{...props}
			value={getValue()}
			unit={i18next.t(props.unit)}
		/>
	);
}

export function UpperTableElementNedcWltp(props: {
	headline: string;
	value: string;
	unit: string;
	model: SSPContentFieldAccessor;
	engine?: SSPContentFieldAccessor;
	add?: any;
	disclaimer?: number | undefined;
}) {
	const wltp = 'wltp';
	//nedc deprecated
	// 	!props.model.has(props.value.replace('xxx', 'wltp')) &&
	// 	props.model.has(props.value.replace('xxx', 'nedc'))
	// 		? 'nedc'
	// 		: 'wltp';

	if (props.value.includes('consumption')) {
		return (
			<UpperTableElementConsumption
				{...props}
				headline={`${i18next.t(props.headline)} (${i18next.t(
					wltp.toUpperCase()
				)})`}
				value={props.value.replace('xxx', wltp)}
			/>
		);
	}

	return (
		<UpperTableElement
			{...props}
			headline={`${i18next.t(props.headline)} (${i18next.t(
				wltp.toUpperCase()
			)})`}
			value={getNumberString(
				props.model.float(props.value.replace('xxx', wltp), NaN),
				i18next.language
			)}
			unit={i18next.t(props.unit)}
		/>
	);
}
