import React, { useContext } from 'react';
import './consumption.scss';
import { SspProviderContext } from '../../../did-utility';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import { OverlayContentProps } from '../interfaces';
import {
	ConsumptionElementType,
	consumptionList,
	ConsumptionListType,
	disclaimerTagsInConsumptionLists,
	getConsumptionValue,
} from '../../../did-utility/src/Functions/consumptionLists';
import Disclaimers from '../../../did-utility/src/Components/Disclaimers';
import i18next from 'i18next';
import { engineTypes } from '../../../did-utility/src/Components/Label/dataLabel';

export default function Consumption(props: OverlayContentProps): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const vehicleDataModel = contentModel.ref('vehicle_data').asModel;
	const engine = contentModel.ref('engine').asModel;
	const engineType = engineTypes.includes(
		engine.str('engine_type', 'Default')
	)
		? (engine.str('engine_type', 'Default') as keyof ConsumptionListType)
		: ('Default' as keyof ConsumptionListType);
	//nedc deprecated
	// const hasNEDCData = vehicleDataModel
	// 	.keys()
	// 	.some(
	// 		key =>
	// 			key.includes('nedc') &&
	// 			!(
	// 				(key.includes('efficiency') &&
	// 					vehicleDataModel.str('nedc_energy_efficiency') ===
	// 						'none') ||
	// 				vehicleDataModel.str('nedc_energy_efficiency') === '-'
	// 			)
	// 	);
	const hasWLTPData = vehicleDataModel
		.keys()
		.some(key => key.includes('wltp'));

	const engineDisclaimers = engine.arr('disclaimers') as Array<{
		Key: string;
		Value: string;
	}>;
	const disclaimerTags: Array<string> = [
		...disclaimerTagsInConsumptionLists(engineType),
	];

	const getConsumption = (c: ConsumptionElementType) => {
		const disclaimerNumber =
			c.disclaimer && disclaimerTags.includes(c.disclaimer)
				? engineDisclaimers.findIndex(d => d.Key === c.disclaimer)
				: -1;
		if (c.hideIfEmpty && !vehicleDataModel.has(c.key)) {
			return <></>;
		}

		return (
			<div
				className='consumption-element'
				key={`consumption-element-${c.key}`}
			>
				<p className='long'>
					{i18next.t(c.headline)}
					{disclaimerNumber !== -1 && (
						<sup>{disclaimerNumber + 1}</sup>
					)}
				</p>
				<p className='long bold'>
					{getConsumptionValue(c, vehicleDataModel)}
				</p>
			</div>
		);
	};

	return (
		<div className='consumption'>
			<div className='sticky-top'>
				{props.closeBtn()}
				<h1 className='side-padding-overlay'>
					{i18next.t('ConsumptionDataHeader')}
				</h1>
			</div>
			<div className='side-padding-overlay'>
				{hasWLTPData && (
					<div className='consumption-table'>
						<h4>{i18next.t('WltpValuesTableHeadline')}</h4>
						{consumptionList.wltp &&
							consumptionList.wltp[engineType].map(
								getConsumption
							)}
					</div>
				)}
				{/*{(hasNEDCData ||*/}
				{/*	i18next.exists('MissingNedcValuesDisclaimer')) && (*/}
				{/*	<div className='consumption-table'>*/}
				{/*		<h4>{i18next.t('NedcValuesTableHeadline')}</h4>*/}
				{/*		{!hasNEDCData ? (*/}
				{/*			<div className='consumption-element'>*/}
				{/*				<p className='p2 missing'>*/}
				{/*					{i18next.t('MissingNedcValuesDisclaimer')}*/}
				{/*				</p>*/}
				{/*			</div>*/}
				{/*		) : (*/}
				{/*			<>*/}
				{/*				{consumptionList.nedc &&*/}
				{/*					consumptionList.nedc[engineType].map(*/}
				{/*						getConsumption*/}
				{/*					)}*/}
				{/*			</>*/}
				{/*		)}*/}
				{/*	</div>*/}
				{/*)}*/}
				<div className='disclaimer-area'>
					<p className='small disclaimer pre-wrap'>
						{i18next.t('ConsumptionDisclaimer')}
					</p>
					{disclaimerTags.length > 0 && (
						<Disclaimers
							tagsUsed={disclaimerTags}
							complete={engineDisclaimers}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
