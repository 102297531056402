import { OverlayEnum } from '../enums';
import { SET_OVERLAYS_ACTION } from './store';
import { SspProviderContext } from './interfaces';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';

export function addOverlay(
    store: SspProviderContext,
    overlay: OverlayEnum | SSPContentVO
) {
    store.dispatch({
        type: SET_OVERLAYS_ACTION,
        value: [...store.state.overlays, overlay],
    });
}

export function goBack(store: SspProviderContext) {
    store.dispatch({
        type: SET_OVERLAYS_ACTION,
        value: store.state.overlays.slice(0, -1),
    });
}
