import { CURRENCY, CURRENCY_ISO } from '../constants';
import { getSetup } from './getSetup';

const market = getSetup()?.market || null;

export function getNumberString(
    num: number,
    local: string,
    unit?: string,
    digits?: number
) {
    if (
        (unit === CURRENCY || unit === CURRENCY_ISO) &&
        market &&
        Object.keys(market).some(key => key.startsWith('currency_'))
    ) {
        const customString = num
            .toLocaleString('en-US', {
                minimumFractionDigits:
                    market['currency_decimal_posistions'] || 2,
                maximumFractionDigits:
                    market['currency_decimal_posistions'] || 2,
            })
            .replaceAll(',', 'g')
            .replaceAll(
                '.',
                market['currency_decimal_separator'] ||
                    (1.1).toLocaleString(local).charAt(1) // typical dec. sep. for local
            )
            .replaceAll(
                'g',
                market['currency_show_digit_grouping']
                    ? market['currency_grouping_separator'] ||
                          (1000).toLocaleString(local).charAt(1) // typical group. sep. for local
                    : ''
            );
        return (
            (market['currency_previous'] || '') +
            customString +
            (market['currency_after'] || '') +
            (!(market['currency_previous'] || market['currency_after'])
                ? ' ' + market['currency']
                : '')
        );
    }

    var options = {};

    if (unit === CURRENCY && market.currency) {
        options = {
            style: 'currency',
            currency: market.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
    }

    if (digits !== undefined) {
        options = {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits,
        };
    }

    var formatter = new Intl.NumberFormat(local, options);

    if (unit === CURRENCY_ISO) {
        unit = market.currency || '';
    }

    return (
        (!Number.isNaN(num) ? formatter.format(num) : '-') +
        (unit && unit !== CURRENCY ? ' ' + unit : '')
    );
}
