import { useContext, useEffect, useState } from 'react';
import './styles.scss';
import { SspProviderContext } from '../../../index';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import { getSetup } from '../../Functions/getSetup';

export function OfflineLamp() {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content
        ? store.state.content.asModel
        : new SSPContentVO('').asModel;
    const labelDate = contentModel.ref('second_display').createdTime;
    const assetDate = new Date(labelDate);
	const setup = getSetup();

    const today = new Date();
    const twoMonthsAgo = new Date(today);
    twoMonthsAgo.setMonth(today.getMonth() - 2);

    useEffect(() => {
        window.addEventListener('online', () => {
            setIsOnline(navigator.onLine);
        });
        window.addEventListener('offline', () => {
            setIsOnline(navigator.onLine);
        });
    }, []);

    if (!isOnline || setup.market.name === 'DEU' && assetDate < twoMonthsAgo)
        return (
            <div
                className={`offline-lamp ${setup.market.name === 'DEU' &&
                    assetDate < twoMonthsAgo ? 'blink' : ''
                }`}
            />
        );

    return null;
}
