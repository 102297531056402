import React from 'react';
import { SspProviderContext } from '../SspProvider/interfaces';
import { SET_QR_ACTION } from '../SspProvider/store';

export default function clickLinks(
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    store: SspProviderContext
) {
    e.stopPropagation();
    const target = e.target as HTMLElement;
    if (target.className === 'clickable-qr-link') {
        store.dispatch({
            type: SET_QR_ACTION,
            value: (target.lastChild as HTMLElement).innerText,
        });
    }
}
