import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { CarouselProps } from './interfaces';
import './styles.scss';

export default function NormalCarousel({
    children,
    setCurrentIndex,
    currentIndex = null,
    margin,
    avoidJumping,
    looping,
    fitContent = false,
    className,
    slidingDisabled = false,
}: CarouselProps) {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [isJumping, setIsJumping] = useState<boolean>(false);

    const dragging = useRef(false);
    const startPosX = useRef(0);
    const currentTranslate = useRef(0);
    const prevTranslate = useRef(0);
    const currentIndexInternal = useRef<number | null>(0);
    const sliderRef = useRef<HTMLDivElement>(null);
    const animationRef = useRef<number | null>(null);
    const threshHold = window.innerWidth / 6;
    const transition = 0.3;
    const maxTranslate = dimensions.width * 0.05;
    const minTranslate =
        -(dimensions.width + (margin || 0)) * (children.length - 1 + 0.05);
    const minTranslateLoop =
        -(dimensions.width + (margin || 0)) * (children.length - 1);
    const loopDistance = (dimensions.width + (margin || 0)) * children.length;

    const setPositionByIndex = useCallback(
        (w = dimensions.width) => {
            currentTranslate.current =
                currentIndexInternal.current! * -(w + (margin || 0));
            prevTranslate.current = currentTranslate.current;
            setSliderPosition();
        },
        [dimensions.width]
    );

    function getElementDimensions(element: HTMLDivElement) {
        return { width: element.clientWidth, height: element.clientHeight };
    }

    const transitionOn = useCallback(() => {
        if (sliderRef.current)
            sliderRef.current.style.transition = `transform ${transition}s ease-out`;
    }, [transition]);

    const transitionOff = () => {
        if (sliderRef.current) sliderRef.current.style.transition = 'none';
    };

    // react to resize
    useEffect(() => {
        window.addEventListener('resize', () => {
            if (sliderRef.current) {
                const { width, height } = getElementDimensions(
                    sliderRef.current
                );
                setDimensions({ width, height });
                setPositionByIndex(width);
            }
        });
    }, []);

    // watch for a change in currentIndex prop
    useEffect(() => {
        if (
            avoidJumping &&
            currentIndex !== null &&
            currentIndexInternal.current !== null &&
            Math.abs(currentIndex - currentIndexInternal.current) > 1
        ) {
            transitionOn();
            currentIndexInternal.current +=
                currentIndex > currentIndexInternal.current ? 1 : -1;
            setIsJumping(true);
            setPositionByIndex();
            setTimeout(() => {
                setIsJumping(false);
                transitionOff();
                currentIndexInternal.current = currentIndex;
                setPositionByIndex();
            }, transition * 1000);
        } else if (currentIndex !== currentIndexInternal.current) {
            transitionOn();
            currentIndexInternal.current = currentIndex;
            setPositionByIndex();
        }
    }, [currentIndex]);

    useLayoutEffect(() => {
        if (sliderRef.current) {
            // no animation on startIndex
            transitionOff();
            // set width after first render
            setDimensions(getElementDimensions(sliderRef.current));

            // set position by startIndex
            setPositionByIndex(getElementDimensions(sliderRef.current).width);
        }
    }, [setPositionByIndex]);

    function scrollUp() {
        const slides = document.getElementsByClassName('slide-container');
        for (let i = 0; i < slides.length; i++) {
            slides[i].scrollTo(0, 0);
        }
    }

    function pointerStart(index: number) {
        return function (event: React.PointerEvent) {
            transitionOn();
            currentIndexInternal.current = index;
            startPosX.current = event.pageX;
            dragging.current = !slidingDisabled;
            animationRef.current = requestAnimationFrame(animation);
            if (sliderRef.current) sliderRef.current.style.cursor = 'grabbing';
        };
    }

    function pointerMove(event: React.PointerEvent) {
        if (
            !document
                .querySelector('.video-controls') // no swiping in video controls
                ?.contains(event.target as any) &&
            dragging.current
        ) {
            const currentPositionX = event.pageX;
            currentTranslate.current =
                prevTranslate.current + currentPositionX - startPosX.current;

            if (looping) {
                if (
                    currentTranslate.current > 0 &&
                    currentPositionX - startPosX.current > threshHold
                ) {
                    transitionOff();
                    currentTranslate.current -= loopDistance;
                }

                if (
                    currentTranslate.current < minTranslateLoop &&
                    currentPositionX - startPosX.current < -threshHold
                ) {
                    transitionOff();
                    currentTranslate.current += loopDistance;
                }
            }
        }
    }

    function pointerEnd() {
        // HACK: Non-Null Assertion operator
        transitionOn();
        cancelAnimationFrame(animationRef.current!);
        dragging.current = false;
        const movedBy = currentTranslate.current - prevTranslate.current;

        // if looping and first / last slide and jumped in onPointerMoved
        if (
            looping &&
            currentIndexInternal.current === 0 &&
            movedBy < -dimensions.width
        ) {
            currentIndexInternal.current = children.length - 1;
        } else if (
            looping &&
            currentIndexInternal.current === children.length - 1 &&
            movedBy > dimensions.width
        ) {
            currentIndexInternal.current = 0;
        } else {
            // if moved enough negative then snap to next slide if there is one
            if (
                movedBy < -threshHold &&
                currentIndexInternal.current! < children.length - 1
            ) {
                currentIndexInternal.current! += 1;
                scrollUp();
            }

            // if moved enough positive then snap to previous slide if there is one
            if (movedBy > threshHold && currentIndexInternal.current! > 0) {
                currentIndexInternal.current! -= 1;
                scrollUp();
            }
        }

        transitionOn();

        setPositionByIndex();
        sliderRef.current!.style.cursor = 'grab';
        // if setCurrentIndex prop - call it
        if (setCurrentIndex) setCurrentIndex(currentIndexInternal.current!);
    }

    function animation() {
        setSliderPosition();
        if (dragging.current) requestAnimationFrame(animation);
    }

    function setSliderPosition() {
        if (!sliderRef.current) return;
        var actualTranslate = currentTranslate.current;
        if (!looping) {
            if (currentTranslate.current > maxTranslate)
                currentTranslate.current = maxTranslate;
            if (currentTranslate.current < minTranslate)
                currentTranslate.current = minTranslate;
            actualTranslate = currentTranslate.current;
        }
        sliderRef.current.style.transform = `translateX(${actualTranslate}px)`;
    }

    return (
        <div
            className={`carousel-slider-root${
                className ? ' ' + className : ''
            }`}
        >
            <div className='carousel-slider' ref={sliderRef}>
                {children.map((child, index) => {
                    return (
                        <div
                            key={child.key}
                            onPointerDown={pointerStart(index)}
                            onPointerMove={pointerMove}
                            onPointerUp={pointerEnd}
                            onPointerLeave={() => {
                                if (dragging.current) pointerEnd();
                            }}
                            className='carousel-slide-outer'
                            style={{
                                marginRight:
                                    margin && index !== children.length - 1
                                        ? margin
                                        : 0,
                            }}
                        >
                            <div
                                className='carousel-slide'
                                style={{
                                    width: `${dimensions.width}px`,
                                    height: fitContent
                                        ? 'fit-content'
                                        : `${dimensions.height}px`,
                                }}
                            >
                                {child}
                            </div>
                        </div>
                    );
                })}
                {looping && (
                    <>
                        <div
                            className='carousel-slide-outer loop'
                            style={{
                                left: -1 * (dimensions.width + (margin || 0)),
                                width: dimensions.width,
                            }}
                        >
                            <div
                                className='carousel-slide'
                                style={{
                                    width: `${dimensions.width}px`,
                                    height: fitContent
                                        ? 'fit-content'
                                        : `${dimensions.height}px`,
                                }}
                            >
                                {children[children.length - 1]}
                            </div>
                        </div>
                        <div
                            className='carousel-slide-outer loop'
                            style={{
                                left:
                                    children.length *
                                    (dimensions.width + (margin || 0)),
                                width: dimensions.width,
                            }}
                        >
                            <div
                                className='carousel-slide'
                                style={{
                                    width: `${dimensions.width}px`,
                                    height: fitContent
                                        ? 'fit-content'
                                        : `${dimensions.height}px`,
                                }}
                            >
                                {children[0]}
                            </div>
                        </div>
                    </>
                )}
                {isJumping && (
                    <div
                        className='carousel-jumping-cover'
                        style={{
                            left:
                                currentIndexInternal.current! *
                                (dimensions.width + (margin || 0)),
                            width: dimensions.width,
                        }}
                    >
                        {currentIndex !== null ? children[currentIndex] : <></>}
                    </div>
                )}
            </div>
        </div>
    );
}
