import React from 'react';
import './styles.scss';

export default function Disclaimers(props: {
	tagsUsed: Array<any>;
	complete: { Key: string; Value: string }[];
}) {
	return (
		<div className='disclaimer-tags'>
			<table>
				<tbody>
					{props.complete.map((disclaimer, index) =>
						props.tagsUsed.includes(disclaimer.Key) ? (
							<tr key={`disclaimer-${index}`}>
								<td>
									<p className='small'>{index + 1}.</p>
								</td>
								<td>
									<p className='small'>{disclaimer.Value}</p>
								</td>
							</tr>
						) : (
							<React.Fragment key={`disclaimer-${index}`} />
						)
					)}
				</tbody>
			</table>
		</div>
	);
}
