import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './styles.scss';
import { VideoPlayerProps } from './interfaces';
import ReactSlider from 'react-slider';
import { videoSrcFetch } from '../../Functions/videoSrcFetch';
import { logVideoChange } from '../../ActivityLogger/util';
import i18next from 'i18next';

var controlsTimeout: NodeJS.Timeout | undefined;
export default function VideoPlayer(props: VideoPlayerProps): JSX.Element {
    const {
        video,
        visible,
        play,
        pause,
        volumeOn,
        volumeOff,
        ...videoTagProps
    } = props;

    const [playing, setPlaying] = useState<boolean>(false);
    const [showingControls, setShowingControls] = useState<boolean>(
        videoTagProps.controls !== false
    );
    const [volume, setVolume] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [duration, setDuration] = useState<number>(60);
    const [loading, setLoading] = useState<boolean>(true);
    const [wasStarted, setWasStarted] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    // triggered when closed (unless react strict mode is used)
    useLayoutEffect(
        () => () => {
            if (video) logVideoChange(video, 'Video Closed');
            window.dispatchEvent(new CustomEvent('restart-timer'));
        },
        [video]
    );

    // set playing to false when new video is chosen and add src to video
    useEffect(() => {
        if (video?.asAsset.url) {
            setPlaying(false);
            setLoading(true);
            videoSrcFetch(
                video.asAsset.url,
                videoRef,
                videoTagProps.autoPlay,
                () => {
                    setError(false);
                    setLoading(false);
                },
                () => setError(true)
            );
        }
    }, [video]);

    // pause if video is not current slide in slider aka not visible
    useEffect(() => {
        if (videoRef.current && videoRef.current.currentSrc) {
            if (visible) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [visible]);

    const formatTime = (time: number) => {
        const seconds = Math.floor(time % 60);
        return `${Math.floor(time / 60) % 60}:${
            seconds < 10 ? 0 : ''
        }${seconds}`;
    };

    const togglePlay = () => {
        if (videoRef.current && videoRef.current.src) {
            if (videoRef.current.paused || videoRef.current.ended) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    };

    const timeout = () => {
        setShowingControls(false);
    };

    function handleTimeline(value: number) {
        if (videoRef.current) {
            videoRef.current.currentTime =
                (value / 100) * videoRef.current.duration;
            if (videoRef.current.paused || videoRef.current.ended) {
                videoRef.current.play();
            }
            clearTimeout(controlsTimeout);
            controlsTimeout = setTimeout(timeout, 3000);
        }
    }

    function handleVolume() {
        if (videoRef.current) {
            setVolume(!volume);
            videoRef.current.volume = volume ? 1 : 0;
            clearTimeout(controlsTimeout);
            controlsTimeout = setTimeout(timeout, 3000);
        }
    }

    function handlePlayerClick(e: any) {
        if (e.target.className !== 'play-button') {
            setShowingControls(true);
            clearTimeout(controlsTimeout);
            controlsTimeout = setTimeout(timeout, 3000);
        }
    }

    return (
        <div
            className={`video-player${
                videoTagProps.controls === false ? ' no-controls' : ''
            }`}
            onClick={e => handlePlayerClick(e)}
            onTouchStart={e => handlePlayerClick(e)}
        >
            <video
                {...videoTagProps}
                controls={false}
                ref={videoRef}
                onDurationChange={video => {
                    setDuration(video.currentTarget.duration);
                }}
                onTimeUpdate={video => {
                    setCurrentTime(video.currentTarget.currentTime);
                }}
                onClick={togglePlay}
                onPlay={() => {
                    setPlaying(true);
                    setWasStarted(true);
                    if (video && videoTagProps.controls !== false) {
                        logVideoChange(video, 'Video Play');
                        window.dispatchEvent(new CustomEvent('pause-timer'));
                    }
                }}
                onPause={() => {
                    setPlaying(false);
                    if (video && videoTagProps.controls !== false) {
                        logVideoChange(video, 'Video Pause');
                        window.dispatchEvent(new CustomEvent('restart-timer'));
                    }
                }}
                onEnded={() => {
                    if (video && videoTagProps.controls !== false) {
                        logVideoChange(video, 'Video Ended');
                        window.dispatchEvent(new CustomEvent('restart-timer'));
                    }
                }}
            />
            {!playing && !wasStarted && video?.asAsset.posterUrl && (
                <img
                    className='poster-img'
                    src={video?.asAsset.posterUrl}
                    alt={''}
                />
            )}
            {!playing && (
                <div className='dark-overlay' onClick={togglePlay}>
                    {loading && !error && <div />}
                </div>
            )}
            {!playing && !loading && (
                <div className='play-button' onClick={togglePlay}>
                    {play}
                </div>
            )}
            <div
                className={`video-controls${
                    showingControls && !loading ? ' showing' : ' not-showing'
                }`}
            >
                <div className='controls-play' onClick={togglePlay}>
                    {playing ? pause : play}
                </div>
                <p className='time left'>{formatTime(currentTime)}</p>
                <div className='timeline'>
                    <ReactSlider
                        className='slider'
                        value={(currentTime / duration) * 100}
                        onChange={handleTimeline}
                        onSliderClick={handleTimeline}
                        renderThumb={(props: any, state: any) => (
                            <div {...props}>
                                <div>
                                    <div />
                                </div>
                            </div>
                        )}
                    />
                </div>
                <p className='time right'>{formatTime(duration)}</p>
                <div className='volume' onClick={handleVolume}>
                    {volume ? volumeOff : volumeOn}
                </div>
            </div>
            {error && (
                <div className='video-error'>
                    <h2>
                        {i18next.t('VideoErrorHeadline')}
                        <br />
                    </h2>
                    <p>{i18next.t('VideoErrorText')}</p>
                </div>
            )}
        </div>
    );
}
