export enum NavEnum {
    Overview,
    Equipment,
    TechData,
}

export enum OverlayEnum {
    Accessories,
    MediaCenter,
    Colors,
    Rims,
    QRCode,
    Leasing,
    Tire,
    Consumption,
    Menu,
}

export enum InfoIconEnum {
    'none',
    '#info-icon',
    '#play-icon',
    '#picture-icon',
}
