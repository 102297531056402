import React, { useContext, useEffect, useRef, useState } from 'react';
import { SspProviderContext } from '../../../index';
import { videoSrcFetch } from '../../Functions/videoSrcFetch';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import { getSetup } from '../../Functions/getSetup';

export default function HeaderContent() {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content
        ? store.state.content.asModel
        : new SSPContentVO('').asModel;
    const salesGroup = contentModel.ref('salesgroupref');
    const videoRef = useRef<HTMLVideoElement>(null);
    const [headerAssetIndex, setHeaderAssetIndex] = useState<number>(0);
    const setup = getSetup();

    let videoUrl: string | null = null;
    if (
        contentModel.has('driving_scene_asset') &&
        contentModel.ref('driving_scene_asset').asAsset.fileProperties.size <=
            300000000
    ) {
        if (
            setup.brand === 'audi' ||
            (setup.brand === 'vw' &&
                contentModel.integer('modelyear') > 2023) ||
            (contentModel.integer('modelyear') === 2023 &&
                contentModel.str('model_revision') &&
                contentModel.str('model_revision') === 'MP2')
        ) {
            if (
                !contentModel
                    .ref('driving_scene_asset')
                    .asModel.arr('tags')
                    .includes('HIDDEN')
            ) {
                videoUrl = contentModel.ref('driving_scene_asset').asAsset.url;
            }
        }
    }

    if (
        videoUrl === null &&
        salesGroup &&
        salesGroup.asModel.has('modelvideo') &&
        salesGroup.asModel.ref('modelvideo').asAsset.fileProperties.size <=
            300000000
    ) {
        videoUrl = salesGroup.asModel.ref('modelvideo').asAsset.url;
    }

    const headerAssets = contentModel.reflist('header_assets');

    useEffect(() => {
        if (videoRef.current && videoRef.current.currentSrc) {
            if (store.state.overlays.length > 0) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
        }
    }, [store.state.overlays.length]);

    useEffect(() => {
        if (videoUrl) videoSrcFetch(videoUrl, videoRef, true);
    }, []);

    if (videoUrl) {
        return (
            <video
                loop={true}
                muted={true}
                ref={videoRef}
                autoPlay={true}
                playsInline={true}
            />
        );
    }

    if (
        headerAssets &&
        headerAssets.length > 0 &&
        headerAssets[headerAssetIndex].contentType === 'assetImage'
    ) {
        return (
            <img
                src={headerAssets[headerAssetIndex].asAsset.url}
                alt={
                    headerAssets[headerAssetIndex].asAsset.fileProperties
                        .originalFilename
                }
                onLoad={() => {
                    if (headerAssets.length > 1)
                        setTimeout(
                            () =>
                                setHeaderAssetIndex(prevState => {
                                    return prevState === headerAssets.length - 2
                                        ? 0
                                        : prevState + 1;
                                }),
                            10000
                        );
                }}
            />
        );
    }

    return <></>;
}
