import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { registerSW } from './did-utility/src/Functions/serviceWorkerRegistration';
import { getSetup } from './did-utility/src/Functions/getSetup';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const params = new URLSearchParams(window.location.search);

registerSW(getSetup()?.password || '', 'vw', [
	'/vw/index.html',
	'/vw/src/assets/fonts/VWText-Regular.otf',
	'/vw/src/assets/fonts/VWText-Bold.otf',
	'/vw/src/assets/fonts/VWHead.ttf',
	'/vw/src/assets/fonts/VWHead-Light.ttf',
	'/vw/src/assets/fonts/VWHead-Bold.ttf',
]);

if (params.get('iframe') === 'true') {
	let url = new URL(window.location.href);
	url.searchParams.delete('iframe');
	root.render(<iframe className='app' title='DID' src={url.toString()} />);
} else {
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);
}
