import SSPContentFieldAccessor from 'sspcontentext/src/model/accessor/SSPContentFieldAccessor';

export function hasHeaderContent(contentModel: SSPContentFieldAccessor) {
    if (
        contentModel.has('driving_scene_asset') &&
        !contentModel
            .ref('driving_scene_asset')
            .asModel.arr('tags')
            .includes('HIDDEN')
    ) {
        return true;
    }

    const salesGroup = contentModel.ref('salesgroupref');
    if (salesGroup && salesGroup.asModel.has('modelvideo')) {
        return true;
    }

    const headerAssets = contentModel.reflist('header_assets');
    if (headerAssets && headerAssets.length > 0) {
        return true;
    }

    return false;
}
